import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { BaseService } from 'src/app/core/services/base.service';
import { MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { WorkflowLookup } from 'src/app/shared/decorators/workflow-lookup.decorator';
import { BaseWorkflowComponent } from '../base-workflow/base-workflow.component';
import { OnlinePaymentWorkflowState } from 'src/app/shared/models/workflow/states/online-payment-workflow-state.model';
import { OnlinePaymentRequest } from 'src/app/shared/models/process/online-payment-request.model';
import { OnlinePaymentModel } from 'src/app/shared/models/workflow/models/online-payment.model';
import { PaymentService } from 'src/app/core/services/payment-service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkflowStepService } from 'src/app/core/services/workflow-step.service';
import { NotificacionType } from 'src/app/shared/enums/notification-type.enum';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { HttpResponseModel } from 'src/app/shared/models/http-response-model';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { RouteDialogType } from '../../dialogs/route-dialog/route-dialog.component';
import { CompanyModel } from 'src/app/shared/models/systems/company.model';
import { DOCUMENT, Location } from '@angular/common';
import { StatesRequestVerifyDataModel } from '../../states-request-verify/states-request-verify.component';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { OnlineAppointmentStatus } from 'src/app/shared/enums/online-appointment-status.enum';
import { UserService } from 'src/app/core/services/user.service';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-online-payment-workflow',
  templateUrl: './online-payment-workflow.component.html',
  styleUrls: [
    '../base-workflow/base-workflow.component.css',
    './online-payment-workflow.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
@WorkflowLookup('OnlinePaymentWorkflowComponent')
export class OnlinePaymentWorkflowComponent extends BaseWorkflowComponent implements OnInit {  
  state: OnlinePaymentWorkflowState = new OnlinePaymentWorkflowState();

  // Model passed by payment module to workflow
  sourceModel: OnlinePaymentModel;

  savingDialogRef: MatDialogRef<LoadingDialogComponent, any>;

  showState:boolean = false;
  statesRequestVerifyDataModel: StatesRequestVerifyDataModel;
  activatingAccount: boolean;
  onlineAppointmentStatus: OnlineAppointmentStatus;

  constructor(
    protected route: ActivatedRoute,
    protected location: Location,
    private router: Router,
    private paymentService: PaymentService,
    private translation: L10nTranslationService,
    private dialog: MatDialog,
    protected publicProfileService: PublicProfileService,
    private routeDialogService: RouteDialogService,
    private appStorageService: AppStorageService,
    protected baseService: BaseService,
    protected messageBusService: MessageBusService,
    protected workflowService: WorkflowService,
    protected workflowStepService: WorkflowStepService,  
    private appointmentService: AppointmentService,
    private userService: UserService,
    private authService: AuthService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(DOCUMENT) protected document: Document
  ){
    super(location, route, baseService, messageBusService, publicProfileService, workflowService, workflowStepService, document);
  }
  
  ngOnInit() {
    if (this.data)
        this.sourceModel = this.data as OnlinePaymentModel;

    if(this.data && this.data.idVerificacion){
      this.checkOnlineAppointmentStatus();
    }
    else{
      this.startWorkflow();
    }
  }

  checkOnlineAppointmentStatus(){
    this.appointmentService.getStatus(this.data.idVerificacion).subscribe({
      next: this.getStatusNext.bind(this),
      error: _ => this.messageBusService.serviceError(new ServiceErrorMessage("Error getting request status"))
    });
  }

  private getStatusNext(status: OnlineAppointmentStatus): void {
    this.onlineAppointmentStatus = status;

    if(this.onlineAppointmentStatus == OnlineAppointmentStatus.POR_COMPLETAR){
      this.showState = false;
      this.startWorkflow();
    }
    else if(this.onlineAppointmentStatus == OnlineAppointmentStatus.USUARIO_POR_REGISTRAR){
      this.showState = false;

      this.activatingAccount = true;

      this.userConfirmation().subscribe(this.userConfirmationNext.bind(this));
    }
    else if(this.onlineAppointmentStatus == OnlineAppointmentStatus.COMPLETADA) {
      this.authService.isLoggedInFull().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.isLoggedInFull.bind(this));
    }
    else{
      this.configStatusView();
      this.showState = true;
    }
  }

  private userConfirmationNext(result : boolean): void {
    setTimeout(() => this.activatingAccount = false, 500);

    if (result) {
      this.configStatusView();
      this.showState = true;
    }
    else {
      this.messageBusService.serviceError(new ServiceErrorMessage("Error confirming user"));
    }
  }

  private isLoggedInFull(isLoggedIn: boolean): void {
    if( isLoggedIn) {
      this.stepIndex = this.model.items.length - 1;
      this.startWorkflow();
    }
    else {
      this.router.navigate(['welcome', 'login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    } 
  }

  private userConfirmation():Observable<boolean>{
    return this.userService.postConfirmation(this.data.idVerificacion)
    .pipe(
      map((response)=>{
        return response.success;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }

  configStatusView(){
    this.statesRequestVerifyDataModel = new StatesRequestVerifyDataModel();

          this.statesRequestVerifyDataModel.companyLogoUrl = 'assets/images/cliniweb/logo-cliniweb-phr.svg';
          this.statesRequestVerifyDataModel.mainImageUrl = 'assets/images/cliniweb/Campana.gif';
          this.statesRequestVerifyDataModel.showActionButton = true;
          this.statesRequestVerifyDataModel.showHomeButton = false;

          switch(this.onlineAppointmentStatus){
            case OnlineAppointmentStatus.USUARIO_POR_REGISTRAR:
              this.statesRequestVerifyDataModel.buttonActionFn = this.continueWithPayment.bind(this);
              this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.continueWithPayment';
              this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.accountActivatedInCliniweb';
              break;
            case OnlineAppointmentStatus.COMPLETADA:
              this.statesRequestVerifyDataModel.buttonActionFn = this.gotoHome.bind(this);
              this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.buttonHomeText';
              this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.scheduledAppointment';
              break;
            case OnlineAppointmentStatus.VENCIDA:
                this.statesRequestVerifyDataModel.buttonActionFn = this.gotoSignup.bind(this);
                this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.gotoSignup';
                this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.expiredAppointment';
                this.statesRequestVerifyDataModel.textDescriptionRefI18n = 'shared.statesRequestVerify.expiredAppointmentDescription';
                break;
          }
  }

  
  continueWithPayment(){
    this.showState = false;

    this.startWorkflow();
  }

  gotoHome(){
    this.router.navigate(['/']);
  }

  gotoSignup(){
    this.router.navigate(['signup']);
  }

  startWorkflow(){
    // Set this workflow as active
    this.workflowService.setWorkflowTypeActive(STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE);

    // Get state
    this.getState().subscribe((state:OnlinePaymentWorkflowState)=>{
      this.state = state;

      this.workflowService.setWorkflowDataState(this.state);
      this.init();
    });

    this.progressBarModel.closeFn = this.closeWorkflow.bind(this);
  }

  // Override base function
  calculateProgressBarProgress(workflowStepsCount: number, currentStepIndex: number) {
    // If the user is on the last step
    /*if (currentStepIndex == (workflowStepsCount - 1))
      return 96;*/
    
    if (currentStepIndex == (workflowStepsCount - 2))
      return 55;
    
    return (100 / workflowStepsCount) * (currentStepIndex + 1);
  }

  getState() : Observable<OnlinePaymentWorkflowState> {
    var state = this.workflowService.getWorkflowDataState() as OnlinePaymentWorkflowState;    
    let bootstrapState = this.appStorageService.getItemSession(STORAGE.ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE) as OnlinePaymentModel;

    // First alway get it from idSolicitudPorVerificar
    if (this.sourceModel && this.sourceModel.idVerificacion) {
      console.log("Get state from request");
      return this.getStateFromRequest(this.sourceModel.idVerificacion);
    }
    // Build it from source model
    else if (this.sourceModel) {
      console.log("Build state from request");
      return this.buildStateFromModel(this.sourceModel);
    }
    // Get it from browser storage
    else if (state) {
      console.log("Get state from local storage");
      return of(state);
    }       
    else if (bootstrapState)  {
      console.log("Build state from session storage bootstrap");
      this.sourceModel = bootstrapState;

      this.appStorageService.removeSessionItem(STORAGE.ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE);

      return this.buildStateFromModel(this.sourceModel);
    }
    else {
      // This can happend if the user left the tab open with the workflow
      // When session expire the session variable with the workflow state will disappear
      // Go back to home
      this.router.navigate(['/']);
      throw "State expired";
    }    
  }

  getStateFromRequest(requestId: string) : Observable<OnlinePaymentWorkflowState> {
    let state : OnlinePaymentWorkflowState;
    
    // If we do not get the state from localstorage first we will loose the request data from the state
    state = this.workflowService.getWorkflowDataState(STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE);

    if (!state)
      state = new OnlinePaymentWorkflowState();

    return this.paymentService.getOnlinePaymentRequest(requestId)
      .pipe(
        map((request:OnlinePaymentRequest) => {
          state.requestModel = request;
          state.idVerificacion = requestId;
          
          state.setPublicProfile(request.profile);

          return state;
        }),
        catchError((err) => {
          this.baseService.handleServiceError(err, "Error getting online payment request");

          this.router.navigate(['/not-found']);

          return of(state);
        })
      );
  }

  buildStateFromModel(sourceModel: OnlinePaymentModel) : Observable<OnlinePaymentWorkflowState> {
    let state = new OnlinePaymentWorkflowState();
    let onlinePaymentRequest = new OnlinePaymentRequest();

    onlinePaymentRequest.idResponsableServicioDestino = sourceModel.idResponsableServicio;
    onlinePaymentRequest.nombreResponsableServicioDestino = sourceModel.nombreResponsableServicio;
    onlinePaymentRequest.urlAvatarResponsableServicioDestino = sourceModel.urlAvatarResponsableServicio;
    onlinePaymentRequest.nicknameResponsableServicioDestino = sourceModel.nickname;
    onlinePaymentRequest.especialidadResponsableServicioDestino = sourceModel.especialidadResponsableServicio;     
    onlinePaymentRequest.idEmpresaDestino = sourceModel.idEmpresa;
    onlinePaymentRequest.checkoutUrl = this.document.location.origin + '/pagos/checkout/';      
    onlinePaymentRequest.localizacion = this.locale.language;
    onlinePaymentRequest.emailResponsableServicio = sourceModel.emailResponsableServicio;      
    onlinePaymentRequest.idEmpresaResponsableServicio = sourceModel.idEmpresaResponsableServicio;

    onlinePaymentRequest.perfilPublico = sourceModel.perfilPublico;

    return this.baseService.getCompanyByLicense()
    .pipe(switchMap((company:CompanyModel)=>{
      onlinePaymentRequest.idEmpresaOrigen = company.id;
      onlinePaymentRequest.nombreEmpresaOrigen = company.name;

      state.requestModel = onlinePaymentRequest;
      state.idVerificacion = '';

      return of(state);
    }),      
    catchError((err) => {
      this.baseService.handleServiceError(err, "Error getting online appointment request");
      return of(state);
    }));
  }  
  
  closeWorkflow() {

    // Only show the message if the user is logged in
    if (this.state && this.state.getUserId() > 0) {

      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.paymentCancelDialog.title");
      dialogData.message = this.translation.translate("shared.paymentCancelDialog.message");
      dialogData.confirmText = this.translation.translate("shared.paymentCancelDialog.confirmText");   
      dialogData.cancelText = this.translation.translate("shared.paymentCancelDialog.cancelText");
      dialogData.showHeaderIcon = false;
      dialogData.showCancelButton = true;
      dialogData.loadLicense = true;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: 'cancel-appointment-dialog'
      });

      dialogRef.afterClosed().subscribe(accepted => {

        if(accepted){
          this._closeWorkflow();
        }
      });
    }
    else {
      this._closeWorkflow();
    }    
  }

  private _closeWorkflow() {
    this.messageBusService.showHeader();

    this.workflowCleanup();

    if(this.routeDialogService.isOpen(RouteDialogType.PAYMENT)){
      this.routeDialogService.closeDialog(RouteDialogType.PAYMENT);
    }
    else{
      let url = this.getPublicProfileUrl(this.state.requestModel);  
  
      this.router.navigate([url]);
    }
  }

  updateWorkflowStateData():Observable<void>{    
    
    // If we have a created request lets update it
    if(this.state.idVerificacion) {
      return this.paymentService.updateOnlinePaymentRequest(
        this.state.idVerificacion,
        this.state.requestModel)
        .pipe(map((id:number)=>{
          this.state.requestModel.id = id;
          this.workflowService.setWorkflowDataState(this.state);
        }),
        catchError(err=>{
          this.baseService.handleServiceError(err, "Error updating online payment request");
          return of<void>();
        }));
    }
    else{
      return of(void 0);

      /*return this.paymentService.createOnlinePaymentRequest(this.state.requestModel)
        .pipe(map((idVerificacion:string)=>{
          this.state.idVerificacion = idVerificacion;
          this.workflowService.setWorkflowDataState(this.state);

          this.location.go(`${this.baseService.getLanguage() + this.router.url}/${idVerificacion}`);
        }));*/
    }
  }

  completeWorkflowStateData():Observable<void>{
    this.sendLoadingMessage();    

    // Check if we have a SolicitudPorVerificar created
    // In case the user was logged in for example the request will not be created at this point
    if (this.state.idVerificacion) {
      return this.confirmRequest();
    }
    else {
      // Create initial request
      return this.createInitilRequest()
        .pipe(switchMap(() => {

          // Confirm request
          return this.confirmRequest();          
        })
      );     
    }
  }

  createInitilRequest() : Observable<void> {    
    this.state.requestModel.tipoNotificacion = NotificacionType.ACTIVAR_CUENTA_PARA_PAGO_EN_LINEA;

    return this.paymentService.createOnlinePaymentRequest(this.state.requestModel)
      .pipe(map((idVerificacion: string) => {
        this.state.idVerificacion = idVerificacion;
        this.state.requestModel.codigoVerificacion = idVerificacion;
        this.workflowService.setWorkflowDataState(this.state, STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE);        
      }),
      catchError((err) => {
        console.log('There was an error creating the request');
        this.baseService.handleServiceError(err, "Error creating online payment request");
        return of(void 0);
      })      
    );
  }

  confirmRequest() : Observable<void>{
    return this.paymentService.confirmOnlinePaymentRequest(
      this.state.idVerificacion, this.state.requestModel)
      .pipe(
        map((request:OnlinePaymentRequest)=>{
          this.state.requestModel = request;
          this.workflowService.setWorkflowDataState(this.state);

          this.sendCompletedMessage();          
        }),
        catchError((err) => {
          console.log('There was an error processing the request');
          console.log(err);

          this.sendErrorMessage();

          return of(void 0);
        })      
      );
  }

  workflowCleanup() {
    this.workflowService.removeWorkflowData(STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE);
    this.appStorageService.removeSessionItem(STORAGE.ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE);
  }

  clearCreditCardData() {
    this.state.setCreditCardNumber("");
    this.state.setCreditCardCvc("");
    
    this.workflowService.setWorkflowDataState(this.state, STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE);
  }

  sendLoadingMessage() {            
    let data = new DialogData();
    
    data.title = "";
    data.description = "Realizando pago";
    data.showHeaderIcon = false;
    data.loading = true;
    data.showCancelButton = false;
    data.showConfirmButton = false;
    data.confirmText = "";
    data.headerIconClass = "phr-iconset-state-ok";

    let dialogRef = this.dialog.open(LoadingDialogComponent, {          
      panelClass: 'loading-dialog',
      data: data,
      disableClose: true
    });
    
    this.savingDialogRef = dialogRef;
  }

  sendCompletedMessage() {        
    this.savingDialogRef.close();
    
    let data = new DialogData();
    
    data.title = "Pago aprobado";
    data.description = "Enviamos el detalle del cargo a tu correo";
    data.showHeaderIcon = true;
    data.loading = false;
    data.showCancelButton = false;
    data.showConfirmButton = true;
    data.confirmText = "Listo";
    data.headerIconClass = "phr-iconset-ok-circle";    

    let dialogRef = this.dialog.open(LoadingDialogComponent, {          
      panelClass: 'loading-dialog',
      data: data,
      disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(accepted => {    
      let publicProfileIdentifier = this.state.requestModel.nicknameResponsableServicioDestino;
      
      // Clean up
      this.workflowCleanup();      

      if (this.routeDialogService.isOpen(RouteDialogType.PAYMENT))
        this.routeDialogService.closeDialog(RouteDialogType.PAYMENT);
      else 
        this.router.navigate(['perfil/' + publicProfileIdentifier]);
    });
  }

  sendErrorMessage() {
    this.clearCreditCardData();

    this.savingDialogRef.close();
    
    let data = new DialogData();
    
    data.title = "Upssss";
    data.description = "Hubo un problema al procesar el pago";
    data.showHeaderIcon = true;
    data.loading = false;
    data.showCancelButton = false;
    data.showConfirmButton = true;
    data.confirmText = "Listo";
    data.headerIconClass = "phr-iconset-error";    

    let dialogRef = this.dialog.open(LoadingDialogComponent, {          
      panelClass: 'loading-dialog',
      data: data,
      disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(accepted => {    
      let publicProfileIdentifier = this.state.requestModel.nicknameResponsableServicioDestino;


    });
  }
}