import { BaseService } from "../services/base.service";
import { MessageBusService, ServiceErrorMessage } from "../services/message-bus.service";
import { RouteLoaderService } from "../services/route-loader.service";
import { take } from "rxjs/operators";
import { TerritorialDivisionsService } from "../services/territorial-divisions.service";
import { forkJoin, Observable } from "rxjs";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { AppInitializationService } from "../services/app-initializer.service";

export function appInitializer(
    baseService: BaseService,
    routeLoader: RouteLoaderService,
    messageBusService: MessageBusService,
    territorialDivisionService: TerritorialDivisionsService,
    router: Router,
    platformId: Object,
    appInitializationService: AppInitializationService
) {
    return () => new Promise<void>((resolve) => {
        appInitializationService.setInitialized();

        let activeProcesses = 0;

        const manageProcesses = {
            start: () => activeProcesses++,
            finish: () => {
                activeProcesses--;
                if (activeProcesses === 0) {
                    resolve();
                }
            },
        };

        if (isPlatformBrowser(platformId)) {
            baseService.checkVersion();
        }

        const appConfig$ = baseService.getAppConfig().pipe(take(1));
        const observables: Observable<any>[] = [appConfig$];

        if (isPlatformBrowser(platformId)) {
            const country$ = territorialDivisionService.getCountryByIp().pipe(take(1));
            observables.push(country$);
        }

        manageProcesses.start();

        forkJoin(observables).subscribe({
            next: (results) => handleInitializationResults(results),
            error: (error) => handleInitializationError(error),
        });

        function handleInitializationResults(results: any[]) {
            const directory = results[0];

            manageProcesses.start();
            routeLoader.load(directory.localizacionDefecto, directory.idEmpresa)
            .subscribe({
                complete: () => {
                    router.initialNavigation();
                    manageProcesses.finish();
                }
            });

            manageProcesses.finish();
        }

        function handleInitializationError(error: any) {
            router.initialNavigation();
            resolve();

            setTimeout(() => {
                messageBusService.serviceError(new ServiceErrorMessage("Error during initialization"));
            }, 100);
        }
    });
}