import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

export enum LogLevel {
  Off = 0,
  Error = 1,
  Warning = 2,
  Info = 3,
  Verbose = 4
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  logError(text:any){
    this.postLog(text, LogLevel.Error);
  }
  logWarning(text:any){
    this.postLog(text, LogLevel.Warning);
  }
  logInfo(text:any){
    this.postLog(text, LogLevel.Info);
  }
  logVerbose(text:any){
    this.postLog(text, LogLevel.Verbose);
  }

  private postLog(text:any, level:LogLevel){
    if(isPlatformServer(this.platformId) && environment.logLevel >= level){
      const timestamp = new Date().toLocaleString();
      let body = {
        text: `[${timestamp}] ${JSON.stringify(text)}`,
        level: level
      }
      this.http.post(environment.gatewayUrl + 'api/logs', body).subscribe({
        next: () => {
          console.log('Log enviado correctamente.');
        },
        error: (err) => {
          console.error('Error al enviar el log:', err);
        }
      });
    }
  }
}
