import { ErrorHandler, Injectable } from '@angular/core';
import { MessageBusService, ServiceErrorMessage } from '../services/message-bus.service';
import { LogService } from '../services/log.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private messageBusService: MessageBusService,
        private logService: LogService
    ){
    }

    handleError(error: any): void {

        console.error('GlobalErrorHandler' + error);

        this.logService.logError(error);
        
        var message = new ServiceErrorMessage(error || 'Error desconocido', error);
        this.messageBusService.serviceError(message);
    }
}