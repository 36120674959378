import { Inject, Injectable, Optional, PLATFORM_ID, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { L10nConfig, L10nLocale, L10nTranslationService, L10N_CONFIG, L10N_LOCALE } from 'angular-l10n';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Link } from 'src/app/shared/models/people/link.model';
import { ConfiguracionServicioAutenticacionPHR, OidcUserManagerSettings, PHRVersion, PortalPhr } from 'src/app/shared/models/systems/portal-phr.model';
import { environment } from 'src/environments/environment';
import { AppStorageService, STORAGE } from './app-storage.service';
import { BaseHttpServiceUnauthorizeException, HttpBaseService } from './http-base-service';
import { PortalPHRService } from './portal-phr.service';
import { ComponentFinishLoadingMessage, LocaleChangeMessage, MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import * as moment from 'moment';
import { Directory, DirectoryType } from 'src/app/shared/models/systems/directory.model';
import { DirectoryService } from './directory.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { LegalAgreements } from 'src/app/shared/interfaces/legal-agreements';
import { LicenseService } from './license.service';
import { Licence } from 'src/app/shared/models/systems/license.model';
import { CompanyModel } from 'src/app/shared/models/systems/company.model';
import { AuthenticationServiceProvider } from 'src/app/shared/enums/authentication-service-provider';
import { LegalAgreementType } from 'src/app/shared/enums/legal-agreements-type.enum';
import { LegalAgreementsDialogComponent, LegalAgreementsDialogData } from 'src/app/shared/components/dialogs/legal-agreements-dialog/legal-agreements-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LearnMoreDialogComponent } from 'src/app/shared/components/dialogs/learn-more-dialog/learn-more-dialog.component';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
import { Request } from 'express';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private localeChangedSource:Subject<L10nLocale>; 
  localeChanged$:Observable<L10nLocale>;
  isBrowser: boolean;

  constructor(
    private http: HttpBaseService,
    private messageBusService: MessageBusService,
    private router: Router,
    private appStorageService: AppStorageService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private translation: L10nTranslationService,
    private portalPhrService: PortalPHRService,
    private directoryService : DirectoryService,
    private licenseService: LicenseService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private meta: Meta,
    private logService: LogService
  ) {
    this.localeChangedSource = new Subject<L10nLocale>();
    this.localeChanged$ = this.localeChangedSource.asObservable();
    this.setConfigTranslation();

    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  setConfigTranslation(){
    this.translation.onChange().subscribe({
      next: (locale: L10nLocale) => {
        this.localeChangedSource.next(locale);
      }
    });
    this.translation.onError().subscribe({
      next: (error: any) => {
          if (error) console.log(error);
      }
    });
  }

  checkVersion(): void{
    const version = this.appStorageService.getItem(STORAGE.VERSION);

    if(version){
      if(version !== environment.version){
        this.appStorageService.clearAll();
        this.appStorageService.setItem(STORAGE.VERSION, environment.version);
      }
    }
    else{
      this.appStorageService.setItem(STORAGE.VERSION, environment.version);
    }
  }

  getAppConfig():Observable<PortalPhr> {
    let url: string = '';
    let path: string = '';

    // Si está en el navegador, usa window.location
    if (this.isBrowser) {
      url = encodeURIComponent(window.location.origin);
      path = window.location.pathname;
    } 
    else if (this.request) {
      // Si está en el servidor (SSR), usa la URL de la request
      url = encodeURIComponent(`${this.request.protocol}://${this.request.get('host')}`);
      path = this.request.url;
    }

    return this.portalPhrService.getByUrl(url)
      .pipe(switchMap((directory: PortalPhr) =>{
        this.logService.logVerbose("registro de portal encontrado: " + directory.nombre);

        this.setDefaultLanguage(directory.localizacionDefecto);

        this.messageBusService.showSignupLoginHeaderMenu(directory.versionPhr >= PHRVersion.V2);

        if (this.isBrowser) {
          this.appStorageService.setItemSession(STORAGE.DIRECTORY, directory);

          if(environment.production){
            GoogleAnalyticsService.loadGoogleAnalytics(directory.analyticsKey);
          }
        }

        // set title page
        const pathSegments = path.split('/');
        const perfilIndex = pathSegments.findIndex(segment => segment === 'perfil');
        if (perfilIndex !== -1 && perfilIndex < pathSegments.length - 1) {

          const nickname = pathSegments[perfilIndex + 1];

          if(nickname){
            let language = directory.localizacionDefecto ?? 'es';
            let accountName = directory.nombreEmpresaDestinoDirectorio ?? '';
            let publicProfileUrl = `api/perfiles-publicos/${nickname}?lenguaje=${language}&nombreCuenta=${accountName}`;
            return this.http.get<PublicProfile>(publicProfileUrl, null, false)
            .pipe(switchMap(profile => {
              const title = profile.browserTitle + ' - ' + directory.titulo;
              this.titleService.setTitle(title);
              this.meta.updateTag({ name: 'description', content: profile.browserDescription });
              return of(directory);
             }));
          }
          else{
            this.titleService.setTitle(directory.titulo);
            return of(directory);
          }
        }
        else{
          this.titleService.setTitle(directory.titulo);
          return of(directory);
        }
      }),
      catchError(err => {
        this.logService.logError('Error al obtener configuración PortalPHR');
        throw err;
      })
    );
  }

  getMenuLinks(language: string = '', companyId?: number): Observable<Link[]>{
    language = language ? language : this.getLanguage();

    if(!companyId){
      companyId = this.getCompanyId();
    }

    let _menuLinks: Link[] = this.isBrowser ? this.appStorageService.getItemSession(STORAGE.MENU, true) : [];

    if(_menuLinks && _menuLinks.length > 0){
      return of(_menuLinks);
    }
    else {
      return this.http.get<Link[]>("api/layout/menu/" + language + "?idEmpresa=" + companyId, null, false)
      .pipe(map((links: Link[])=>{

        if (this.isBrowser){
          this.appStorageService.setItemSession(STORAGE.MENU, links, true);
        }
        
        return links;
      }));
    }
  }

  getMenuLinkByAreaId(language: string, areaId: number) : Observable<Link | undefined> {
    return this.getMenuLinks(language)
      .pipe(map((links: Link[]) => {
        return links? links.find(l => l.idAreaSistema == areaId) : undefined;
      })
    );
  }

  getDirectory():PortalPhr {
    let directory = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory;
  }

  getAuthenticationProviders():ConfiguracionServicioAutenticacionPHR[]{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory.clientesOidc;
  } 

  getCompanyId():number {
    let directory = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.idEmpresa ? directory.idEmpresa : 0;
  }

  getCompanyByLicense():Observable<CompanyModel>{
    return this.licenseService.getLicensePhr(this.getCompanyId())
    .pipe(map(license=>{

      let company: CompanyModel = new CompanyModel();

      if(license == null){
        company.id = environment.idEmpresaCliniwebPHR ;
        company.name = this.getCliniwebDomain();
      }
      else{
        company.id = this.getCompanyId();
        company.name = this.getCompanyName();
      }

      return company;
    }));
  }

  isLicensedCompany(idResponsableServicio?:number):Observable<boolean>{
    return this.licenseService.getLicensePhr(this.getCompanyId(), idResponsableServicio)
    .pipe(map((license:Licence)=>{
      return license != null;
    }));
  }

  getCliniwebDirectoryCompanyId():number {
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);
    return directory && directory.idEmpresaDestinoDirectorio ? directory.idEmpresaDestinoDirectorio : 0;
  }

  getCliniwebDirectoryCompanyName():string {
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.nombreEmpresaDestinoDirectorio? directory.nombreEmpresaDestinoDirectorio : "";
  }

  getCliniwebDirectoryLocationCompanyIds():string {
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.idsEmpresasLocalidades? directory.idsEmpresasLocalidades : "";
  }

  getCompanyName():string {
    let directory = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.nombre ? directory.nombre : "";
  }

  getMenuComponentName():string {
    let directory = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.nombreMenu ? directory.nombreMenu : "";
  }

  getBackUrlCompany():string{
    let url = this.appStorageService.getItem(STORAGE.REFERRER);
    return url;
  }

  getUrlCompany():string{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);
    return directory && directory.urlCorporativo ? directory.urlCorporativo : '';
  }

  getDisclaimer(): LegalAgreements | null {
    const directory: PortalPhr = this.getDirectory();
    return directory.descargoResponsabilidad?.trim().length ? JSON.parse(directory.descargoResponsabilidad) : null;
  }

  getPrivacyPolicy(): LegalAgreements | null {
    const directory: PortalPhr = this.getDirectory();
    return directory.politicasPrivacidad?.trim().length ? JSON.parse(directory.politicasPrivacidad) : null;
  }

  getTermsAndConditions(): LegalAgreements | null {
    const directory: PortalPhr = this.getDirectory();
    return directory.terminosCondiciones?.trim().length ? JSON.parse(directory.terminosCondiciones) : null;
  }

  getPublicProfileVersion():string {
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.versionPerfilPublico ? directory.versionPerfilPublico : "";
  }

  getLanguage():string {
    if(isPlatformBrowser(this.platformId)){
      let language = this.appStorageService.getItem(STORAGE.LOCALE);

      if(language){
        return language.language;
      }
      else{
        return "es";
      }
    }
    else{
      return "es";
    }
  }

  getCliniwebDomain(){
    return environment.nombreEmpresaCliniwebPHR;
  }

  isCliniwebCompany() : boolean {
    let directory = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory && directory.nombre ? directory.nombre == this.getCliniwebDomain() : false;
  }

  setDefaultLanguage(language:string){

    let languageString: any | null = null;

    if (this.isBrowser) {
      languageString = this.appStorageService.getItem(STORAGE.LOCALE);
    }

    if(languageString){
      this.setLocale(languageString);
    }
    else {
      let schema = this.l10nConfig.schema.find(l => l.locale.language == language);

      if(!schema)
        schema = this.l10nConfig.schema[0];

      this.setLocale(schema.locale);

      if (this.isBrowser) {
        this.appStorageService.setItem(STORAGE.LOCALE, JSON.stringify(schema.locale));
      }
    }
  }

  setLocale(locale: L10nLocale): void {
    this.translation.setLocale(locale);
    let message = new LocaleChangeMessage();

    message.language = locale.language;

    this.messageBusService.localeChange(message);
  }

  getHeaderName():string{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory? directory.nombreEncabezado : "";
  }

  getPoliciesControlName():string{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory? directory.nombrePoliticas : "";
  }

  getDirectoryClass():string {
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory ? directory.nombreClaseEstilo : "cliniweb";
  }

  getDirectoryTitle(): string{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory? directory.titulo : "";
  }

  getDirectoryUrlFavicon(): string{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory? directory.urlFavicon : "";
  }
  
  getRouteLinkByArea(idAreaSistema:number):string {
    try{
      let menu:Link[] = this.appStorageService.getItemSession(STORAGE.MENU, true);

      let link = menu.find(link => link.idAreaSistema == idAreaSistema);

      return link ? link.identificadorUrl : '';
    }
    catch{
      return '';
    }
  }

  getDirectoryClientOidc() : OidcUserManagerSettings | null {
    try {    
      let directory : PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

      //TODO allow more than one
      var clienteOidc = directory.clientesOidc.find(c => c.proveedorServicioAutenticacion == AuthenticationServiceProvider.Google 
        || c.proveedorServicioAutenticacion == AuthenticationServiceProvider.Sura);

      if (clienteOidc && clienteOidc.clienteOidc) {
        var oidcClient :  OidcUserManagerSettings = JSON.parse(clienteOidc.clienteOidc);
        oidcClient.authenticationServiceProvider = clienteOidc.proveedorServicioAutenticacion;

        return oidcClient;
      }
    }
    catch {
      console.log("No se pudo parsear el cliente oidc");
    }

    return null;
  }

  getDirectoryDomain(): Observable<string>{
    return this.licenseService.getLicensePhr(this.getCompanyId())
    .pipe(map((license:Licence)=>{
      if(license == null){
        return this.getCliniwebDomain();
      }
      else{
        const directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

        return directory? directory.nombreCuentaPaciente : "";
      }
    }));
  }

  getCliniwebDirectory(companyId: number): Observable<Directory>{
    let key = STORAGE.CLINIWEB_DIRECTORY + companyId;
    let directory:Directory = this.appStorageService.getItem(key);

    if (!directory) {
      return this.directoryService.getByType(companyId, DirectoryType.Cliniweb)
        .pipe(map((directory: Directory) =>{
          this.appStorageService.setItem(key, directory);

          return directory;
        }));
    }
    else {
      return of(directory);
    }
  }

  isMenuLinksVisible(): boolean{
    let directory:PortalPhr = this.appStorageService.getItemSession(STORAGE.DIRECTORY);

    return directory? directory.menuVisible == 'S' : true;
  }

  parseObject<T>(json : any) : T {
    let result = {};

    // Asign all values
    Object.assign(result, json);

    return <T>result as T;
  }

  parseObjectDeep<T>(json : any) : T {
    let result = {} as any;
    var keys = Object.keys(json);

    for(let i=0; i < keys.length; i++) {
      let camelCasePropName = keys[i].charAt(0).toLowerCase() + keys[i].slice(1);
      result[camelCasePropName] = json[keys[i]];
    }   

    return <T>result as T;
  }

  convertObjectToQS(obj : any) : string {
    let str = [];
    
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    
      return str.join("&");
  }

  goToRoute(path:string) {
    let pathQueryParams = path.split('?');
    let queryParams = pathQueryParams[1];

    if(pathQueryParams[1]){
      let params = queryParams.split('&');
      let pair = null;
      let paramsObject:any = {};
  
      params.forEach(p => {
        pair = p.split('=');
        paramsObject[pair[0]] = pair[1];
      });
      
      this.router.navigate([ "/" + pathQueryParams[0]], { queryParams: paramsObject });
    }
    else{
      this.router.navigate([ "/" + pathQueryParams[0]]);
    }
  }

  getSearchSeed() {
    let seed = this.appStorageService.getItem(STORAGE.PROFILE_SEACH_SEED);

    if (!seed) {
      // Generate a 6 digits code
      seed = Math.trunc(Math.random() * 1000000);

      // Store on local storage
      this.appStorageService.setItem(STORAGE.PROFILE_SEACH_SEED, seed);
    }

    return seed;
  }

  getLocationFromIdentifier(territorialDivisionIdentifier: string) {
    let parts = territorialDivisionIdentifier? territorialDivisionIdentifier.split("/") : [""];;
    let country = "", state = "", city = "";

    // If we search by city or state we will alway get the country. 
    // Example "Panama/bocas-del-toro/chiriqui-grande" pais=panama&provincia=bocas-del-toro&ciudad=chiriqui-grande
    if (parts.length > 0)
      country = parts[0];

    // Second we will alway have the state
    if (parts.length > 1)
      state = parts[1];

    // Thrid we will alway have the city
    if (parts.length > 2)
      city = parts[2];

    return {
      country: country,
      state: state,
      city: city
    };
  }

  // AvatarInitials
  getInitialsString(text:string):string {
    let arrayText = text.trim().replace(/-/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s\s+/g, ' ').split(" ");

    let result;
    
    if (arrayText.length > 1) {

        var lastnamePosition = arrayText.length - 1;

        if (lastnamePosition == 0 || (arrayText[lastnamePosition].length >= 2 && arrayText[lastnamePosition].includes('.'))) {
            result = arrayText[0][0] + arrayText[1][0];
        }
        else if (arrayText.length == 4) {
          result = arrayText[0][0] + arrayText[2][0];
        }
        else {
            result = arrayText[0][0] + arrayText[lastnamePosition][0];
        }
    }
    else {
        result = arrayText[0][0];
    }
    
    return result.toUpperCase();
  }

  getHashCodeByString(id:number, text:string):number {
    var hash = 0;
    for (var i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash + id;
  }

  getColorByInitials(id:number, initials:string):string {
    var hashCode = this.getHashCodeByString(id, initials);

    var colors = [
        "#0ACBAC",
        "#17C65D",
        "#C801F2",
        "#FD3A58",
        "#F06C00",
        "#FFC000",
        "#296EFA",
        "#6B9DFF",
        "#7A1FA2",
        "#7C59BE"
    ];

    var index = hashCode % colors.length;

    return colors[index];
  }

  getAvatarInitialObject(id:number, text:string): any{
    
    var initials = this.getInitialsString(text);

    var color = this.getColorByInitials(id, initials);

    return { initials , color };
  }

  public handleServiceError(error: any, errorMessage: string, displayMessage? : string) {
    if (!error || !(error instanceof BaseHttpServiceUnauthorizeException)) {
        let serviceError = new ServiceErrorMessage(errorMessage, displayMessage);
        serviceError.innerError = error;
        this.messageBusService.serviceError(serviceError);
    }
  }

  public getBasicDateFormat(date : Date, includeYear: boolean = true, yearSeparator: string = ', ') : string {    
    let momentDate = moment(date).locale(this.locale.language);
    let dateDay = momentDate.format('DD');
    let dateMonth = momentDate.format('MMM').toLowerCase().replace(".", "");
    let dateYear = momentDate.format('YYYY');
    
    dateMonth = dateMonth.charAt(0).toUpperCase() + dateMonth.slice(1);

    let yearStr = includeYear? (yearSeparator + dateYear) : "";

    let resultDateStr = this.locale.language == "es"?
      (dateDay + " " + dateMonth + yearStr) :
      (dateMonth + " " + dateDay + yearStr);

    return resultDateStr;
  }

  public getMonthName(month: number, uppercaseFirstLetter: boolean = false) : string {
    var monthName = moment(month.toString(), 'M').locale(this.locale.language).format('MMM');
    
    monthName = monthName.toLowerCase().replace(".", "");

    return uppercaseFirstLetter
      ? monthName.charAt(0).toUpperCase() + monthName.slice(1)
      : monthName;
  }

  public getBasePath(path: string){
    return path ? path.split("/")[0].split("?")[0] : '';
  }

  sendComponentFinishLoadingMessage(formaData: FormComponentData){
    if (!formaData)
      throw "FormComponentData cannot be null";
    if (!formaData.idFormulario || !formaData.idControlPadre)
      throw "FormComponentData cannot be null";

    let event = new ComponentFinishLoadingMessage();
    
    event.idFormulario = formaData.idFormulario;
    event.idControl = formaData.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  encryptObj(obj: any): string {
    const _key = CryptoJS.enc.Utf8.parse(environment.cKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.cKey);

    return CryptoJS.AES.encrypt(
        JSON.stringify(obj),
        _key,
        {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
  }

  decryptObj(text: string): any {
    const _key = CryptoJS.enc.Utf8.parse(environment.cKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.cKey);
    const decryptedText = CryptoJS.AES.decrypt(
      text,
      _key,
      {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedText);
  }

  openLegalAgreementsDialog(type:LegalAgreementType){
    let data = new LegalAgreementsDialogData();

    data.type = type;

    this.dialog.open(LegalAgreementsDialogComponent, { data: data, panelClass: ['legal-agreements-dialog'] });
  }

  openLearnMoreDialog(){
    this.dialog.open(LearnMoreDialogComponent, { panelClass: ['learn-more-dialog'] });
  }

  isIOS() : boolean {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } 
    else {
      return typeof(navigator.maxTouchPoints) != 'undefined' 
        && navigator.maxTouchPoints > 2 
        && /MacIntel/.test(navigator.platform);
    }
  }  

  isMacDesktop() : boolean {
    return typeof(navigator.maxTouchPoints) != 'undefined' 
      && navigator.maxTouchPoints == 0 
      && /MacIntel/.test(navigator.platform);
  }

  getDetailedAgeByDate(birthDate:Date): string{
    const now = new Date();
    let years = now.getFullYear() - birthDate.getFullYear();
    let months = now.getMonth() - birthDate.getMonth();
    let days = now.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return this.getDetailedAge(years, months, days);
  }

  getDetailedAge(years: number, months: number, days: number):string{
    const yearSingularText = this.translation.translate('year');
    const yearPluralText = this.translation.translate('years');
    const monthSingularText = this.translation.translate('month');
    const monthPluralText = this.translation.translate('months');
    const daySingularText = this.translation.translate('day');
    const dayPluralText = this.translation.translate('days');

    const parts: string[] = [];

    if (years > 0) {
      parts.push(`${years} ${years > 1 ? yearPluralText : yearSingularText}`);
    }

    if (months > 0) {
      parts.push(`${months} ${months > 1 ? monthPluralText : monthSingularText}`);
    }

    if (days > 0) {
      parts.push(`${days} ${days > 1 ? dayPluralText : daySingularText}`);
    }

    return parts.join(', ');
  }

  isSerializedHtml(text: string): boolean{
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlRegex.test(text);
  }

  createSafeUrl(data: Blob): string {
    const objectUrl = URL.createObjectURL(data);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
    
    // Convierte SafeUrl a string seguro
    return this.sanitizer.sanitize(SecurityContext.URL, safeUrl) || '';
  }
}
